::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #4a5568;
  border-radius: 39px;
}
::-webkit-scrollbar-thumb:hover {
  background: #718096;
}
::-webkit-scrollbar-thumb:active {
  background: #718096;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.select_country_image {
  width: 20px;
}

.dropdown_item {
  padding: 6px 16px;
}

.react-datepicker__current-month {
  display: none;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  gap: 18px;
}
.react-datepicker__month-read-view > span {
  font-weight: 600;
  font-size: 14px;
}
.react-datepicker__month-read-view--down-arrow {
  top: 3px;
}

.react-datepicker__year-read-view > span {
  font-weight: 600;
  font-size: 14px;
}
.react-datepicker__year-read-view--down-arrow {
  top: 3px;
}
.menu_iconButton > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.order_user_picture {
  height: 64px;
  width: 64px;
  object-fit: cover;
  border-radius: 10px;
}

.ql-editor:active {
  border-color: #0093ff;
  box-shadow: 0 0 0 1px #0093ff;
}
.ql-editor:focus-visible {
  border-color: #0093ff !important;
  box-shadow: 0 0 0 1px #0093ff !important;
}
